export const messages = {
  "common.close": "Sulje",
  "common.yes": "Kyllä",
  "common.no": "Ei",
  "common.cancel": "Peruuta",
  "common.error": "Jokin meni pieleen. Yritä hetken kuluttua uudelleen.",
  "common.language.fi": "Suomi",
  "common.language.en": "Englanti",
  "common.language.swe": "Ruotsi",
  "common.language.ee": "Viro",
  "common.language.pl": "Puola",
  "common.language.ru": "Venäjä",
  "login.meta.title": "Raksanappi – Kirjaudu sisään",
  "beta.notification": "Huomaathan, että palvelu on vielä työn alla!",
  "new-raksanappi.notification":
    "Raksanapin kautta tehtyjen tilausten toimituksissa voi juuri nyt ilmetä tilapäistä viivettä. Pahoittelemme häiriötä!",
  "login.title": "Kirjaudu sisään",
  "login.username": "Sähköposti",
  "login.password": "Salasana",
  "login.submit": "Kirjaudu sisään",
  "login.forgot-password": "Unohditko salasanasi?",
  "login.failed": "Virheellinen sähköpostiosoite tai salasana.",
  "login.failed.service-unavailable":
    "Kirjautuminen epäonnistui, yritä hetken kuluttua uudelleen",
  "login.user-blocked.text": "Tunnuksesi ei ole aktiivinen. {link}",
  "login.user-blocked.link": "Tee aktivointipyyntö tästä.",
  "login.sso_failed": "L&T-tunnuksilla kirjautuminen epäonnistui.",
  "login.sso_failed_missing_role": "Sinulta puuttuu oikeudet raksanappiin.",
  "login.lt.link": "Kirjaudu sisään L&T-tunnuksilla",
  "login.credentials.text": "Eikö sinulla ole tunnuksia? {link}",
  "login.additional-info.text":
    "Raksanappi on helppo asiointikanava rakennustyömaille. {link}",
  "login.additional-info.link": "Lue lisää",
  "login.credentials.link": "Tilaa tunnukset täältä",
  "accept-terms.title": "Käyttöehdot ja tietosuojaseloste",
  "accept-terms.description":
    "Ottaaksesi palvelun käyttöön sinun tulee hyväksyä käyttöehdot ja tutustua tietosuojaselosteeseen.",
  "accept-terms.accept": "Olen hyväksynyt",
  "accept-terms.terms": "käyttöehdot",
  "accept-terms.and-read": "ja tutustunut",
  "accept-terms.privacy-policy": "tietosuojaselosteeseen",
  "accept-terms.accept-button": "Aloita käyttö",
  "terms-and-conditions": "Käyttöehdot",
  "cookie-policy": "Evästeasetukset",
  "registry-policy": "Tietosuojaseloste",
  "routes.frontpage": "Etusivu",
  "routes.orders": "Tilaukset",
  "routes.archive": "Dokumentit",
  "order-page.roll-off": "Vaihtolavat",
  "order-page.jassikat": "Jassikat",
  "order-page.dry-toilet": "Bajamajat",
  "order-page.frontloader-container": "Etukuormaussäiliöt",
  "order-page.compactor": "Puristimet",
  "order-page.other": "Muu",
  "order-page.other.description":
    "Tästä voit tehdä tilauksen jollekin työmaallasi olevalle jäteastialle, jota ei ole vielä Raksanapissa.",
  "order.vaihtolava.title": "Vaihtolavat",
  "order.jassikat.title": "Jassikat",
  "order.vaihtolava.new": "Uusi vaihtolava",
  "order.vaihtolava.empty": "Tyhjennä vaihtolava",
  "order.vaihtolava.move": "Siirrä vaihtolava",
  "order.vaihtolava.remove": "Vie pois vaihtolava",
  "order.jassikka.new": "Uusi jassikka",
  "order.jassikka.empty": "Tyhjennä jassikka",
  "order.jassikka.remove": "Vie pois jassikka",
  "order.compactor.title": "Puristimet",
  "order.compactor.new": "Uusi puristin",
  "order.compactor.empty": "Tyhjennä puristin",
  "order.compactor.move": "Siirrä puristin",
  "order.compactor.remove": "Vie pois puristin",
  "order.frontloader.title": "Etukuormaussäiliö",
  "order.frontloader.new": "Uusi etukuormaussäiliö",
  "order.frontloader.empty": "Lisätyhjennys etukuormaussäiliö",
  "order.frontloader.remove": "Vie pois etukuormaussäiliö",
  "order.dry-toilet.title": "Bajamaja",
  "order.dry-toilet.new": "Uusi Bajamaja",
  "order.dry-toilet.empty": "Lisätyhjennys Bajamaja",
  "order.dry-toilet.remove": "Vie pois Bajamaja",
  "order.form.date": "Ajankohta",
  "order.form.asap": "Seuraava vapaa aika",
  "order.form.choose-date": "Valitse päivä",
  "order.form.warning.title":
    "Tilausta ei voida lähettää. Täydennä puuttuvat tiedot:",
  "order.form.waste-type.error": "Valitse jätteen tyyppi",
  "order.form.contact-number.error": "Pakollinen tieto",
  "order.form.contact-number.warning": "Puhelinnumero",
  "order.form.choose-date-range": "Valitse ajankohta",
  "order.form.selected-date": "Toivottu ajankohta",
  "order.form.service": "Valitse palvelu",
  "order.form.waste-type": "Jätetyyppi",
  "order.form.special-requests": "Erikoistoiveet",
  "order.form.call-before": "Soitto ennen toimitusta",
  "order.form.call-before.empty": "Soitto ennen tyhjennystä",
  "order.form.call-before.move": "Soitto ennen siirtoa",
  "order.form.call-before.remove": "Soitto ennen noutoa",
  "order.form.contact-number": "Yhteyshenkilön puhelinnumero",
  "order.form.additional-info": "Lisätiedot kuljettajalle",
  "order.form.additional-info.compactor": "Lisätiedot",
  "order.form.to-confirmation": "Jatka vahvistamiseen",
  "order.form.empty-interval.once": "Kertatyhjennys",
  "order.form.empty-interval.repeating": "Säännöllinen tyhjennys",
  "order.form.choose-interval": "Valitse tyhjennysväli",
  "order.form.choose-interval.one-week": "1 vko",
  "order.form.choose-interval.two-week": "2 vko",
  "order.form.choose-interval.four-week": "4 vko",
  "order.form.vaihtolava.move-during-empty":
    "Lavan siirto tyhjennyksen yhteydessä",
  "order.form.vaihtolava.change-during.empty":
    "Jätetyypin vaihto tyhjennyksen yhteydessä",
  "order.form.vaihtolava.low-side": "Matalalaita",
  "order.form.vaihtolava.lifting-lugs": "Nostokorvat",
  "order.form.vaihtolava.special-container": "Erikoislava (esim. Kippilava)",
  "order.form.vaihtolava.special-container.lid-container": "Kansilava",
  "order.form.vaihtolava.special-container.locker-container": "Lokerolava",
  "order.form.vaihtolava.special-container.tipper-container": "Kippilava",
  "order.form.vaihtolava.container-number": "Lavan tunnus (esimerkiksi FK123)",
  "order.form.jassika.container-number": "Jassikan tunnus (esimerkiksi FK123)",
  "order.form.vaihtolava.placeholder":
    "Annathan vain tätä tilausta koskevat lisätiedot. Voit ilmoittaa tässä esim. tarkemmat ajo-ohjeet tai lavan tarkemman sijainnin.",
  "order.form.jassika.placeholder":
    "Annathan vain tätä tilausta koskevat lisätiedot. Voit ilmoittaa tässä esim. tarkemmat ajo-ohjeet tai Jassikan tarkemman sijainnin.",
  "order.form.compactor.info.new":
    "Puristimen toimitus saatavuuden mukaan. Olemme yhteydessä toimituksesta kolmen arkipäivän sisällä.",
  "order.form.compactor.info.date": "Toimitusaika 5 päivää",
  "order.form.compactor.additional-info.placeholder.new":
    "Annathan vain tätä tilausta koskevat lisätiedot. Voit ilmoittaa tässä esim. erityistoiveet puristimen mallista.",
  "order.form.compactor.additional-info.placeholder.other":
    "Annathan vain tätä tilausta koskevat lisätiedot. Voit ilmoittaa tässä esim. tarkemmat ajo-ohjeet tai puristimen tarkemman sijainnin.",
  "order.form.compactor.special-container": "Kakskammiopuristin",
  "order.form.compactor.move-during-empty":
    "Puristimen siirto tyhjennyksen yhteydessä",
  "order.form.frontloader.additional-info.placeholder-new":
    "Annathan vain tätä tilausta koskevat lisätiedot. Voit ilmoittaa tässä esim. erityistoiveet.",
  "order.form.frontloader.additional-info.placeholder-other":
    "Annathan vain tätä tilausta koskevat lisätiedot. Voit ilmoittaa tässä esim. tarkemmat ajo-ohjeet tai säiliön tarkemman sijainnin.",
  "order.form.dry-toilet.info.date": "Toimitusaika 3 arkipäivää",
  "order.form.dry-toilet.type": "Valitse malli",
  "order.form.dry-toilet.additional-info.placeholder":
    "Annathan vain tätä tilausta koskevat lisätiedot. Voit ilmoittaa tässä esim. tarkemmat ajo-ohjeet tai Bajamajojen lukumäärän.",
  "order.form.other.title": "Muu tilaus",
  "order.form.other.description":
    "Tästä voit tehdä tilauksen jollekin työmaallasi olevalle jäteastialle tai jätteelle, jota ei vielä ole Raksanapissa.",
  "order.form.other.additional-info.placeholder":
    "Pyri kuvaamaan tähän mahdollisimman tarkasti millaisesta jätteestä ja jäteastiasta on kyse sekä astiamäärä.",
  "order.confirm.title": "Vahvista tilaus",
  "order.confirm.vaihtolava.new": "Uusi vaihtolava",
  "order.confirm.vaihtolava.empty": "Vaihtolavan tyhjennys",
  "order.confirm.vaihtolava.move": "Vaihtolavan siirto",
  "order.confirm.vaihtolava.remove": "Vaihtolavan pois vienti",
  "order.confirm.compactor.new": "Uusi puristin",
  "order.confirm.compactor.empty": "Puristimen tyhjennys",
  "order.confirm.compactor.move": "Puristimen siirto",
  "order.confirm.compactor.remove": "Puristimen pois vienti",
  "order.confirm.frontloader.new": "Uusi etukuormaussäiliö",
  "order.confirm.frontloader.empty": "Etukuormaussäiliön tyhjennys",
  "order.confirm.frontloader.remove": "Etkuormaussäiliön pois vienti",
  "order.confirm.dry-toilet.new": "Uusi Bajamaja",
  "order.confirm.dry-toilet.empty": "Bajamajan tyhjennys",
  "order.confirm.dry-toilet.remove": "Bajamajan pois vienti",
  "order.confirm.other": "Muu tilaus",
  "order.confirm.date": "Ajankohta",
  "order.confirm.service": "Palvelu",
  "order.confirm.date.once": "Kertatyhjennys",
  "order.confirm.date.repeating": "Säännöllinen tyhjennys: {interval}",
  "order.confirm.container-type": "Astiatyyppi",
  "order.confirm.waste-type": "Jätetyyppi",
  "order.confirm.empty-interval": "Tyhjennysväli",
  "order.confirm.special-requests": "Erikoistoiveet",
  "order.confirm.move-on-delivery": "Siirto tyhjennyksen yhteydessä",
  "order.confirm.change-waste-type-to":
    "Jätetyypin vaihto tyhjennyksen yhteydessä",
  "order.confirm.change-waste-type-to.yes":
    "Kyllä, uusi jätetyyppi: {wasteType}",
  "order.confirm.container-number": "Lavan numero",
  "order.confirm.call-before.new": "Soitto ennen toimitusta",
  "order.confirm.call-before.empty": "Soitto ennen tyhjennystä",
  "order.confirm.call-before.move": "Soitto ennen siirtoa",
  "order.confirm.call-before.remove": "Soitto ennen noutoa",
  "order.confirm.customer": "Asiakas",
  "order.confirm.worksite.number": "Työmaanumero",
  "order.confirm.worksite.address": "Työmaan osoite",
  "order.confirm.subscriber": "Tilaaja",
  "order.confirm.contact": "Yhteyshenkilön puhelinnumero",
  "order.confirm.additional-info": "Lisätiedot kuljettajalle",
  "order.confirm.send": "Lähetä tilaus",
  "order.confirm.error": "Tilauksen lähettäminen epäonnistui.",
  "order.success.title": "Kiitos tilauksesta!",
  "order.success.confirmation-email": "Tilauksenne on toimitettu eteenpäin.",
  "order.success.customerservice":
    "Ongelmatilanteissa tai muutoksissa voit ottaa yhteyttä asiakaspalveluumme Ota yhteyttä -osion kautta.",
  "order.success.archive": "Tarkastele tilaushistoriaasi",
  "orders.open.title": "Avoimet tilaukset",
  "orders.history.title": "Tilaushistoria",
  "order.wastetype": "Jätetyyppi",
  "order.containerType": "Astia",
  "order.deliverytime": "Arv. toimitusaika",
  "order.deliverytimeHistory": "Toimitusaika",
  "order.created-at": "Tilauspäivämäärä",
  "order.subscriber": "Tilaaja",
  "order.empty-interval": "{interval} välein",
  "order.order-type.new": "Uusi",
  "order.order-type.empty": "Tyhjennys",
  "order.order-type.move": "Siirto",
  "order.order-type.remove": "Pois vienti",
  "order.order-type.other": "Muu tilaus",
  "order.order-status.received": "Vastaanotettu",
  "order.order-status.incomplete": "Käsittelyssä",
  "order.order-status.started/reserved": "Ajossa",
  "order.order-status.can't complete": "Ei toteutunut",
  "order.order-status.partially done": "Suoritettu",
  "order.order-status.done": "Suoritettu",
  "order.order-status.done with additions": "Suoritettu",
  "order.order-status.unknown": "Ei tiedossa",
  "task.details.visitWithoutAction.reason.car_blocks": "Auto edessä",
  "task.details.visitWithoutAction.reason.no_key": "Ei avainta",
  "task.details.visitWithoutAction.reason.rasputitsa_slippery_or_not_plowed":
    "Kelirikko, liukas tai auraamatta",
  "task.details.visitWithoutAction.reason.tight_spot": "Ahdas paikka",
  "task.details.visitWithoutAction.reason.angry_dog": "Vihainen koira",
  "task.details.visitWithoutAction.reason.lock_didnt_open_or_freezed":
    "Lukko ei aukea tai lukko on jäässä",
  "task.details.visitWithoutAction.reason.other_issue_approaching":
    "Muu lähestymisongelma",
  "task.details.visitWithoutAction.reason.door_or_gate_locked":
    "Ovi tai portti lukossa",
  "task.details.visitWithoutAction.reason.road_closed": "Tie poikki",
  "task.details.visitWithoutAction.reason.safety_risk": "Turvallisuusriski",
  "task.details.visitWithoutAction.reason.bin_frozen": "Astia jäätynyt kiinni",
  "task.details.visitWithoutAction.reason.bin_problem": "Astiaongelma",
  "task.details.visitWithoutAction.reason.bin_not_found": "Astioita ei löydy",
  "task.details.visitWithoutAction.reason.bin_repeatedly_not_found":
    "Astioita ei löydy (toistuvasti kateissa)",
  "task.details.visitWithoutAction.reason.bin_too_heavy_or_overfilled":
    "Astia painava tai ylitäysi",
  "task.details.visitWithoutAction.reason.bins_empty": "Astiat tyhjiä",
  "task.details.visitWithoutAction.reason.bin_broken": "Astia rikki",
  "task.details.visitWithoutAction.reason.other_bin_issue": "Muu astiaongelma",
  "task.details.visitWithoutAction.reason.container_not_found":
    "Keräilyvälinettä ei löydy",
  "task.details.visitWithoutAction.reason.container_frozen": "Jäätynyt",
  "task.details.visitWithoutAction.reason.container_too_heavy": "Ylipaino",
  "task.details.visitWithoutAction.reason.container_overfilled": "Ylitäysi",
  "task.details.visitWithoutAction.reason.no_hook_bar": "Vetokaari puuttuu",
  "task.details.visitWithoutAction.reason.no_cable_wire_hook":
    "Vaijerilenkit puuttuu",
  "task.details.visitWithoutAction.reason.no_locking_bar":
    "Lukkopalkki puuttuu",
  "task.details.visitWithoutAction.reason.lock_didnt_open": "Lukko ei aukea",
  "task.details.visitWithoutAction.reason.container_broken": "Rikki",
  "task.details.visitWithoutAction.reason.other_container_issue": "Muu ongelma",
  "task.details.visitWithoutAction.reason.car_or_obstacle_blocks":
    "Auto tai este edessä",
  "task.details.visitWithoutAction.reason.sorting_error": "Lajitteluvirhe",
  "task.details.visitWithoutAction.reason.palpa_empty": "Muu syy",
  "task.details.visitWithoutAction.reason.other_reason": "Muu syy",
  "task.details.visitWithoutAction.reason.unknown": "Tuntematon syy",
  "task.details.visitWithoutAction.reason.no_given_reason": "Tuntematon syy",
  "order.open.list.empty":
    "Sinulla ei ole avoimia tilauksia. Luo uusi tilaus aloittamalla ",
  "order.history.list.empty":
    "Tilaushistoriassa ei ole vielä tilauksia. Voit tehdä tilauksia ",
  "documents.common.worknumber": "Työmaanumero",
  "documents.common.site": "Työmaa",
  "documents.common.language": "Kieli",
  "documents.transfer.title": "Siirtoasiakirjat",
  "documents.transfer.deliveryDate": "Toimituspvm",
  "documents.transfer.no-document": "Työkohteella ei ole siirtoasiakirjoja",
  "documents.sorting.title": "Suunnitelma ja ohjeet",
  "documents.sorting.plan-title": "Lajittelusuunnitelma",
  "documents.sorting.plan-file-name": "Lajittelusuunnitelma",
  "documents.sorting.sort-guides-title": "Lajitteluohjeet",
  "documents.sorting.document": "Dokumentti",
  "documents.sorting.no-document": "Työkohteella ei ole lajittelusuunnitelmaa",
  "documents.sorting.guide-fi": "Lajitteluohjeet FI",
  "documents.sorting.guide-en": "Lajitteluohjeet EN",
  "documents.sorting.guide-swe": "Lajitteluohjeet SWE",
  "documents.sorting.guide-ee": "Lajitteluohjeet EE",
  "documents.sorting.guide-pl": "Lajitteluohjeet PL",
  "documents.sorting.guide-ru": "Lajitteluohjeet RU",
  "burgermenu.logout": "Kirjaudu ulos",
  "burgermenu.contact": "Ota yhteyttä",
  "burgermenu.other-services": "Muut palvelut",
  "burgermenu.omalt": "Oma L&T",
  "burgermenu.ynet": "Ympäristönetti",
  "contact.title": "Ota yhteyttä",
  "contact.feedback": "Palaute",
  "contact.offer": "Tarjouspyyntö",
  "contact.other": "Muu asia",
  "contact.form.description.offer":
    "Tästä voit pyytää tarjouksen haluamistasi jätehuollon palveluista työmaallesi. Kuvaile meille palvelutarpeesi sekä työmaasi tiedot ja otamme sinuun yhteyttä mahdollisimman pian.",
  "contact.form.label": "Viestisi",
  "contact.form.placeholder.feedback":
    "Kuvaile palautteesi mahdollisimman tarkasti.",
  "contact.form.placeholder.order":
    "Pyri kuvaamaan tähän mahdollisimman tarkasti millaisesta jäteastiasta ja jätteestä on kyse.",
  "contact.form.placeholder.offer":
    "Kirjoita kuvaus tarvitsemistasi palveluista.",
  "contact.form.placeholder.other":
    "Kuvaile meille kuinka voisimme auttaa ja otamme sinuun yhteyttä mahdollisimman pian.",
  "contact.form.submit.feedback": "Lähetä palaute",
  "contact.form.submit.offer": "Lähetä pyyntö",
  "contact.form.submit.order": "Lähetä viesti",
  "contact.form.submit.other": "Lähetä viesti",
  "contact.form.error": "Viestin lähetys epäonnistui",
  "contact.form.success.title": "Kiitos yhteydenotosta",
  "contact.form.success.return": "Palaa alkuun",
  "sales.user-management": "Käyttäjähallinta",
  "sales.no-user-search-results": "Ei hakutuloksia",
  "sales.send-login-instructions-by-email":
    "Lähetä kirjautumisohjeet sähköpostiin",
  "sales.invite-user-button": "Kutsu uusi käyttäjä",
  "sales.invite-user-page.title": "Käyttäjän luonti",
  "sales.invite-user-page.create-user": "Luo käyttäjä",
  "sales.edit-user-page.title": "Muokkaa käyttäjää",
  "sales.edit-user-page.save-modifications": "Tallenna",
  "sales.user-inputs.title": "Käyttäjän tiedot",
  "sales.user-inputs.firstname": "Etunimi*",
  "sales.user-inputs.lastname": "Sukunimi*",
  "sales.user-inputs.email": "Sähköposti*",
  "sales.user-inputs.phone-number": "Puhelinnumero*",
  "sales.worksite-search.title": "Työmaat",
  "sales.worksite-search.placeholder": "Hae työmaita...",
  "sales.worksite-search.worksite": "Työmaa",
  "sales.worksite-search.loading": "Ladataan...",
  "sales.worksite-search.error":
    "Työkohteiden haku epäonnistui. Yritä myöhemmin uudelleen",
  "sales.user-modification.success": "Käyttäjän muokkaus onnistui",
  "sales.user-modification.failure":
    "Käyttäjän muokkaus epäonnistui. Yritä myöhemmin uudelleen.",
  "sales.user-creation.success": "Käyttäjän luonti onnistui",
  "sales.user-creation.failure":
    "Käyttäjän luonti epäonnistui. Yritä myöhemmin uudelleen.",
  "sales.user-creation.existing-user": "Käyttäjä on jo olemassa",
  "sales.user-creation.existing-user.search-failed":
    "Käyttäjän haku epäonnistui",
  "sales.validations.firstName": "Etunimi",
  "sales.validations.lastName": "Sukunimi",
  "sales.validations.email": "Sähköposti",
  "sales.validations.phone": "Puhelinnumero",
  "sales.validations.notEmpty": "ei saa olla tyhjä",
  "sales.validations.correctFormat": "Tarkista muoto",
  "no-active-worksite": "Sinulla ei ole tällä hetkellä aktiivista työmaata.",
  "no-active-worksite-guide": "Voit ilmoittaa työmaasi tiedot ",
  "no-active-worksite-contact": "Ota yhteyttä -osion kautta."
}

/* Daypicker Localisations */

const MONTHS = [
  "Tammikuu",
  "Helmikuu",
  "Maaliskuu",
  "Huhtikuu",
  "Toukokuu",
  "Kesäkuu",
  "Heinäkuu",
  "Elokuu",
  "Syyskuu",
  "Lokakuu",
  "Marraskuu",
  "Joulukuu"
]

const WEEKDAYS_LONG = [
  "Sunnuntai",
  "Maanantai",
  "Tiistai",
  "Keskiviikko",
  "Torstai",
  "Perjantai",
  "Lauantai"
]

const WEEKDAYS_SHORT = ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La"]

export const FINNISH_DAY_PICKER = {
  months: MONTHS,
  weekdaysLong: WEEKDAYS_LONG,
  weekdaysShort: WEEKDAYS_SHORT
}
